import { createRouter, createWebHistory } from "vue-router";
import { app } from '/src/main.js'

import auth from "../store/modules/auth";

function getTranslation(value) {
  // return the translated value from i18n
  return app.config.globalProperties.$t(value)
}
// VIEWS
import AdminWelcome from "../views/AdminWelcome.vue";
import Welcome from "../views/Welcome.vue";
import PageNotFound from "@/views/errors/PageNotFound.vue";
import Error401Page from "@/views/errors/Error401Page.vue";
import Error403Page from "@/views/errors/Error403Page.vue";
import TrackerApi from "@/apis/TrackerApi";

const playEnvironment = () => {
  return import.meta.env.MODE === 'play';
}

const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: Welcome,
    meta: {
      title: playEnvironment() ? 'pageTitles.playMKPD' : 'Marketpedia'
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminWelcome,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/donation',
    name: 'Donation',
    component: () => import('../views/public/donation/Donation.vue')
  },
  {
    path: '/donation-success',
    name: 'DonationSuccess',
    component: () => import('../views/public/donation/OnSuccess.vue')
  },
  {
    path: '/produits/positionnement',
    name: 'ProduitPositionnement',
    component: () => import('../views/public/produits/Positionnement.vue')
  },
  //path to the landing page after a successful survey submission - probably not in the right place
  {
    path: '/confirmation',
    name: 'confirmation',
    component: () => import('../views/QuestionnairesConfirmationPage.vue'),
    meta: {
      requiresAuth: true
    }
  },

  //path to the landing page after a successful survey submission (pos consumer version) - probably not in the right place
  {
    path: '/confirmation-pos-consumer',
    name: 'confirmation-pos-consumer',
    component: () => import('../views/QuestionnairesConfirmationPageCons.vue'),
    meta: {
      requiresAuth: false
    }
  },

  // ---- ERROR PAGES ----------- //
  {
    // ROUTE FOR 404 ERROR
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: PageNotFound
  },
  {
    // ROUTE FOR 401 ERROR
    path: '/error/401',
    name: 'error401Page',
    component: Error401Page
  },
  {
    // ERROR 403
    path: '/error/403',
    name: 'error403Page',
    component: Error403Page
  },

  // ---- STATIC FOR STORYBOOK ----------- //
  {
    path: '/static',
    name: 'Static',
    component: () => import('../views/ComponentsSurveyLibrary.vue')
  },
  // ---- TEMPLATES - CHARTES GRAPHIQUES ----------- //
  {
    path: '/charte-web',
    name: 'CharteWeb',
    component: () => import('../views/CharteWeb.vue')
  },
  {
    path: '/charte-app',
    name: 'CharteApp',
    component: () => import('../views/CharteApp.vue'),
    meta: {
      requiresAuth: true
    }
  },
  // ---- Toasts and Welcome pages ----------- //

  {
    path: '/toasts',
    name: 'toasts',
    component: () => import('../views/ToastsNotifs.vue')
  },
  {
    path: '/welcomePage',
    name: 'welcomePage',
    component: () => import('../views/WelcomePage.vue')
  },

  // ---- AUTHENTICATION PAGES ----------- //
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "login" */
        '../views/authentication/Login.vue'
      )
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () =>
      import(
        '../views/authentication/SignUp.vue'
      )
  },

  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('../views/authentication/ForgotPassword.vue'),
    meta: {
      title: 'pageTitles.forgotPW'
    }
  },
  // updates password for authenticated user
  {
    path: '/update-password',
    name: 'UpdatePassword',
    component: () => import('../views/authentication/UpdatePassword.vue'),
    meta: {
      requiresAuth: true,
      title: 'pageTitles.updatePW'
    }
  },
  // Updates password for first time users or users that forgot their password
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: () => import('../views/authentication/ResetPassword.vue')
  },
  // ------------- Super Admin Kontrol PAGES ----------   //
  {
    path: '/super-adm-kontrol',
    name: 'SuperAdminKontrol',
    component: () => import('../views/super-admin-kontrol/SuperAdminKontrol.vue'),
    meta: {
      requiresAuth: true
    }
  },
  // ------------- Dashboard ----------   //
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/admin/partneradmin/dashboard/Dashboard.vue'),
    meta: {
      requiresAuth: true,
      title: 'pageTitles.dashboard'
    }
  },
  // ------------- Transactions ----------   //
  {
    path: '/transactions',
    name: 'Transactions',
    component: () => import('../views/admin/partneradmin/transactions/Transactions.vue'),
    meta: {
      requiresAuth: true,
      title: 'pageTitles.projects'
    }
  },
  // ------------- Projects PAGES ----------   //
  {
    path: '/projects',
    name: 'Projects',
    component: () => import('../views/admin/partneradmin/projects/Projects.vue'),
    meta: {
      requiresAuth: true,
      title: 'pageTitles.projects'
    }
  },
  // ------------- Single Projects PAGES ----------   //
  {
    path: '/projects/:orgId/:projectId',
    name: 'ProjectDetail',
    component: () => import('../views/admin/partneradmin/projects/ProjectDetail.vue'),
    meta: {
      requiresAuth: true,
      title: 'pageTitles.projectDetails'
    }
  },
  // ------------- Tags PAGE ----------   //
  {
    path: '/tags/',
    name: 'Tags',
    component: () => import('../views/admin/partneradmin/tags/Tags.vue'),
    meta: {
      requiresAuth: true,
      title: 'pageTitles.userTags'
    }
  },
  // ------------- WORD CLOUD PAGE ----------   //
  {
    path: '/wordcloud/',
    name: 'WordCloud',
    component: () => import('../views/admin/partneradmin/wordCloud/wordCloudGenerator.vue'),
    meta: {
      requiresAuth: true,
      title: 'pageTitles.wordCloud'
    }
  },
  {
    path: '/word-cloud/result',
    name: 'WordCloudResult',
    component: () => import('../views/admin/partneradmin/wordCloud/wordCloudResult.vue'),
    meta: {
      requiresAuth: true,
      title: 'pageTitles.wordCloud'
    },
    props: (route) => ({
      words: route.params.words,
      ...route.params
    })
  },
  // ------------- ORGANIZATIONS PAGES ----------   //
  {
    path: '/organizations',
    name: 'OrganizationList',
    component: () => import('../views/admin/partneradmin/organizations/ClientOrganization.vue'),
    meta: {
      requiresAuth: true,
      title: 'pageTitles.organizations'
    }
  },

  {
    path: '/organizations/:orgId',
    name: 'OrganizationDetail',
    component: () =>
      import('../views/admin/partneradmin/organizations/ClientOrganizationDetail.vue'),

    meta: {
      requiresAuth: true,
      title: 'pageTitles.organizationDetails'
    }
  },
  {
    path: '/organizations/:id/:project_id',
    name: 'OrganizationViewWithProject',
    component: () => import('../views/organizations/OrganizationView.vue'),
    meta: {
      requiresAuth: true,
      title: 'pageTitles.organizationDetails'
    }
  },
  {
    path: '/organizations/create',
    name: 'OrganizationCreate',
    component: () => import('../views/organizations/OrganizationCreate.vue'),
    meta: {
      requiresAuth: true,
      title: 'pageTitles.createOrg'
    }
  },
  {
    path: '/organizations/create-client',
    name: 'OrganizationCreateClient',
    component: () => import('../views/organizations/OrganizationCreateClient.vue'),
    meta: {
      requiresAuth: true,
      title: 'pageTitles.createClientOrg'
    }
  },
  {
    path: '/organizations/:id/edit',
    name: 'OrganizationEdit',
    component: () => import('../views/organizations/OrganizationEdit.vue'),
    meta: {
      requiresAuth: true,
      title: 'pageTitles.editOrg'
    }
  },
  {
    path: '/organizations/permission-form',
    name: 'OrganizationPermissionPage',
    component: () => import('../views/organizations/OrganizationPermissionPage.vue'),
    meta: {
      requiresAuth: true
    }
  },
  // ------------- USERS PAGES ----------   //
  {
    path: '/update-settings',
    name: 'userSettings',
    component: () => import('../views/admin/partneradmin/users/UserSettings.vue'),
    meta: {
      requiresAuth: true,
      title: 'pageTitles.settings'
    }
  },
  {
    path: '/users',
    name: 'UserList',
    component: () => import('../views/admin/partneradmin/users/Users.vue'),
    meta: {
      requiresAuth: true,
      title: 'pageTitles.users'
    }
  },
  {
    path: '/users/:id',
    name: 'UserView',
    props: true,
    component: () => import('../views/admin/partneradmin/users/UserDetail.vue'),
    meta: {
      requiresAuth: true,
      title: 'pageTitles.userDetails'
    }
  },
  {
    path: '/users/:id/activity',
    name: 'UserActivity',
    props: true,
    component: () => import('../views/admin/partneradmin/users/RecentActivity.vue'),
    meta: {
      requiresAuth: true,
      title: 'pageTitles.userActivity'
    }
  },
  {
    path: '/track',
    name: 'Track',
    props: true,
    component: () => import('../views/admin/superadmin/Tracking.vue'),
    meta: {
      requiresAuth: true,
      title: 'pageTitles.activStats'
    }
  },
  // ------------- Odase ---------------   //
  {
    path: '/odase',
    name: 'OdaseHome',
    props: true,
    component: () => import('../views/odase/HomeView.vue'),
    meta: {
      requiresAuth: false,
      title: 'pageTitles.odase'
    }
  },
  {
    path: '/odase/questionnaire',
    name: 'Odase',
    props: true,
    component: () => import('../views/odase/QuestionnaireOdaseFree.vue'),
    meta: {
      requiresAuth: false,
      title: 'pageTitles.odase'
    }
  },
  {
    path: '/odase/results/:uuid/:email',
    name: 'OdaseResult',
    props: true,
    component: () => import('../views/odase/FreeResultsView.vue'),
    meta: {
      requiresAuth: false,
      title: 'pageTitles.odaseReport'
    }
  },
  // ------------- MBP ---------------   //
  {
    path: '/marketing-diagnostic-free-best-practices',
    name: 'mbpHome',
    props: true,
    component: () => import('../views/mbp/HomeView.vue'),
    meta: {
      requiresAuth: false,
      title: 'pageTitles.mbp'
    }
  },
  {
    path: '/mark+/questionnaire',
    name: 'mbp',
    props: true,
    component: () => import('../views/mbp/QuestionnaireMBPFree.vue'),
    meta: {
      requiresAuth: false,
      title: 'pageTitles.mbp'
    }
  },
  {
    path: '/mark+/results/:uuid/:email',
    name: 'mpbResult',
    props: true,
    component: () => import('../views/mbp/FreeResultsView.vue'),
    meta: {
      requiresAuth: false,
      title: 'pageTitles.mbpReport'
    }
  },
  // ------------- Plans PAGE ----------   //
  {
    path: '/plans',
    name: 'Plans',
    component: () => import('../views/admin/partneradmin/plans/Plans.vue'),
    meta: {
      requiresAuth: true,
      title: 'pageTitles.plans'
    }
  },

  // ------------- Products PAGE ----------   //
  {
    path: '/products',
    name: 'Products',
    component: () => import('../views/admin/partneradmin/products/Products.vue'),
    meta: {
      requiresAuth: true,
      title: 'pageTitles.products'
    }
  },
  // ------------- CLIENT ORG PAGES ----------   //
  // THESE ROUTES ARE TO BE DELETED. THEY DUPLICATE THE ORGANIZATION ROUTES.
  {
    path: '/clients',
    name: 'ClientOrg',
    component: () => import('../views/admin/partneradmin/organizations/ClientOrganization.vue'),
    meta: {
      requiresAuth: true,
      title: 'pageTitles.organizations'
    }
  },
  {
    path: '/clients/:orgId',
    name: 'ClientOrgDetail',
    component: () =>
      import('../views/admin/partneradmin/organizations/ClientOrganizationDetail.vue'),
    meta: {
      requiresAuth: true,
      title: 'pageTitles.organizationDetails'
    },
    props: true
  },
  // ------------- QUESTIONNAIRES PAGES ----------   //
  {
    path: '/questionnaires',
    name: 'Questionnaires',
    component: () => import('../views/questionnaires/QuestionnaireList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/questionnaires/assign',
    name: 'QuestionnaireAssignation',
    component: () => import('../views/questionnaires/QuestionnaireAssignation.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/survey/consumer/:id([0-9a-fA-F\\-]{36})/:email',
    name: 'SurveyPosConsumer',
    component: () => import('../views/questionnaires/Questionnaire.vue'),
    meta: {
      hideHeader: true,
      hideFooter: true
      // requiresAuth: true,
    }
  },
  {
    path: '/survey/anonymous/:id([0-9a-fA-F\\-]{36})',
    name: 'SurveyAnonymous',
    component: () => import('../views/questionnaires/Questionnaire.vue'),
    meta: {
      hideHeader: true,
      hideFooter: true
      // requiresAuth: true,
    }
  },
  {
    path: '/survey/:id([0-9a-fA-F\\-]{36})',
    name: 'Survey',
    component: () => import('../views/questionnaires/Questionnaire.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/surveyformkit',
    name: 'SurveyFormkit1',
    component: () => import('../views/survey/SurveyBase.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/surveyformkit/:id([0-9a-fA-F\\-]{36})',
    name: 'SurveyFormkit2',
    component: () => import('../views/survey/SurveyBase.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    // TEMPORARY S5 ANSWERS PAGE. SHOULD BE DELETED SOON
    path: '/questionnaires/s5-answers',
    name: 'S5Answers',
    component: () => import('../components/templates/S5Answers.vue')
    // meta: {
    //   requiresAuth: true,
    // },
  },
  // ------------- POS ANSWERS ----------   //
  {
    path: '/answers/:organisationId/:type/:id/:userId',
    name: 'AnswerList',
    component: () => import('../views/answers/AnswerList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  // ------------- ODASE Questionnaires hub page ----------   //
  {
    path: '/odase/questionnaires/:projectId',
    name: 'OdaseQuestionnairesHub',
    component: () => import('../views/odase/QuestionnaireOdaseHub.vue'),
    meta: {
      requiresAuth: true,
      title: 'pageTitles.odaseSurvey'
    }
  },
  // ------------- ODASE THANK YOU ----------   //
  {
    path: '/odase/calm/submitted',
    name: 'OdaseCalmSubmitted',
    component: () => import('../views/odase/SubmittedCalmOdase.vue'),
    meta: {
      requiresAuth: true,
      title: 'pageTitles.thanks'
    }
  },
  {
    path: '/odase/storm/submitted',
    name: 'OdaseStormSubmitted',
    component: () => import('../views/odase/SubmittedStormOdase.vue'),
    meta: {
      requiresAuth: true,
      title: 'pageTitles.thanks'
    }
  },
  {
    path: '/odase/odase-express/submitted',
    name: 'OdaseExpressSubmitted',
    component: () => import('../views/odase/SubmittedExpressOdase.vue'),
    meta: {
      requiresAuth: true,
      title: 'pageTitles.thanks'
    }
  },
  // ------------- ODASE REPORT ----------   //
  {
    path: '/reports/odase/:id',
    name: 'OdaseReport',
    component: () => import('../views/odase/ResultsView.vue'),
    meta: {
      requiresAuth: true,
      title: 'pageTitles.odaseReport'
    }
  },
  // ------------- RAPPORTS PAGES ----------   //
  {
    path: "/reports",
    name: "Reports",
    component: () => import("../views/rapports/Rapports.vue"),
    meta: {
      requiresAuth: true,
      title: 'pageTitles.odaseReports'
    },
  },
  // {
  //   path: "/rapports/:rapportId",
  //   name: "Rapport",
  //   component: () => import("../views/rapports/Rapport.vue"),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/rapports/:rapportId/edit",
  //   name: "RapportEdit",
  //   component: () => import("../views/rapports/RapportEdit.vue"),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // ------------- MATRICES PAGES ----------   //
  {
    path: '/organizations/:id/matrices/positioning/:projectId?',
    name: 'MatricePositioning',
    component: () => import('@/views/matrices/Positioning.vue'),
    meta: {
      requiresAuth: true,
      matriceFriendlyName: 'Positionnement',
      title: 'pageTitles.positioning'
    }
  },
  {
    path: '/organizations/:id/matrices/consumer/:projectId?',
    name: 'MatriceConsumer',
    component: () => import('@/views/matrices/Consumer.vue'),
    meta: {
      requiresAuth: true,
      matriceFriendlyName: 'Consommateur',
      title: 'pageTitles.consumer'
    }
  },
  // {
  //   path: "/organizations/:id/matrices/brand",
  //   name: "MatriceBrand",
  //   component: () => import("../components/templates/matrices/Brand.vue"),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  {
    path: '/organizations/:id/matrices/brand/:projectId?',
    name: 'MatriceBrand',
    component: () => import('@/views/matrices/Brand.vue'),
    meta: {
      requiresAuth: true,
      matriceFriendlyName: 'Marque',
      title: 'pageTitles.brand'
    }
  },
  {
    path: '/organizations/:id/matrices/pestele/:projectId?',
    name: 'MatricePestele',
    component: () => import('@/views/matrices/Pestele.vue'),
    meta: {
      requiresAuth: true,
      matriceFriendlyName: 'Pestele',
      title: 'pageTitles.pestele'
    }
  },
  {
    path: '/organizations/:id/matrices/ansoff/:projectId?',
    name: 'MatriceAnsoff',
    component: () => import('@/views/matrices/Ansoff.vue'),
    meta: {
      requiresAuth: true,
      matriceFriendlyName: 'Ansoff',
      title: 'pageTitles.ansoff'
    }
  },
  {
    path: '/organizations/:id/matrices/bcg/:projectId?',
    name: 'MatriceBcg',
    component: () => import('@/views/matrices/Bcg.vue'),
    meta: {
      requiresAuth: true,
      matriceFriendlyName: 'BCG',
      title: 'pageTitles.bcg'
    }
  },
  {
    path: '/organizations/:id/matrices/porter/:projectId?',
    name: 'MatricePorter',
    component: () => import('@/views/matrices/Porter.vue'),
    meta: {
      requiresAuth: true,
      matriceFriendlyName: 'Porter',
      title: 'pageTitles.porter'
    }
  },
  {
    path: '/organizations/:id/matrices/mckinsey/:projectId?',
    name: 'MatriceMckinsey',
    component: () => import('../views/matrices/Mckinsey.vue'),
    meta: {
      requiresAuth: true,
      matriceFriendlyName: 'Mckinsey',
      title: 'pageTitles.mckinsey'
    }
  },
  {
    path: '/organizations/:id/matrices/swot-sw/:projectId?',
    name: 'MatriceSWOT-SW',
    component: () => import('../views/matrices/SWOT-SW.vue'),
    meta: {
      requiresAuth: true,
      matriceFriendlyName: 'SWOT-SW',
      title: 'pageTitles.swotsw'
    }
  },
  {
    path: '/organizations/:id/matrices/swot-ot/:projectId?',
    name: 'MatriceSWOT-OT',
    component: () => import('../views/matrices/SWOT-OT.vue'),
    meta: {
      requiresAuth: true,
      matriceFriendlyName: 'SWOT-OT',
      title: 'pageTitles.swotot'
    }
  },
  {
    path: '/organizations/:id/matrices/product-lines/:projectId?',
    name: 'MatriceProductLines',
    component: () => import('../views/matrices/ProductLines.vue'),
    meta: {
      requiresAuth: true,
      matriceFriendlyName: 'Gammes',
      title: 'pageTitles.pL'
    }
  },
  {
    path: '/organizations/:id/matrices/product-lines/segment/:segmentId/:projectId?',
    name: 'MatriceProductLinesSegmentsItem',
    component: () => import('../views/matrices/ProductLines/SegmentsItem.vue'),
    meta: {
      requiresAuth: true,
      matriceFriendlyName: 'Gammes'
    }
  },
  {
    path: '/organizations/:id/matrices/product-lines/product-line/:productLineId',
    name: 'MatriceProductLinesProductLineItem',
    component: () => import('../views/matrices/ProductLines/ProductLineItemDetails.vue'),
    meta: {
      requiresAuth: true,
      matriceFriendlyName: 'Gammes',
      title: 'pageTitles.plDetails'
    }
  },
  {
    path: '/organizations/:id/matrices/competition/:projectId?',
    name: 'MatriceCompetition',
    // Uncomment the following line to direct the user to the competitors dashboard (after matrix completion)
    component: () => import('../views/matrices/Competition.vue'),

    meta: {
      requiresAuth: true,
      matriceFriendlyName: 'Compétition',
      title: 'pageTitles.comp'
    }
    // children: [
    //   {
    //     path: "list",
    //     name: "MatriceCompetitionList",
    //     component: () => import("../views/matrices/Competition/CompetitionList.vue"),
    //   },
    //   {
    //     path: ":competitorId",
    //     name: "MatriceCompetitionItem",
    //     component: () => import("../views/matrices/Competition/CompetitionItem.vue"),
    //   },
    // ]
  },
  // {
  //   path: "/organizations/:id/matrices/competition/list",
  //   name: "MatriceCompetitionList",
  //   component: () =>
  //     import("../views/matrices/Competition/CompetitionList.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     matriceFriendlyName: "Competition",
  //   },
  // },
  // {
  //   path: "/organizations/:id/matrices/competition/:competitorId",
  //   name: "MatriceCompetitionItem",
  //   component: () =>
  //     import("../views/matrices/Competition/CompetitionItem.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     matriceFriendlyName: "Competition",
  //   },
  // },
  // {
  //   path: "/organizations/:id/matrices/competition/:competitorId/edit",
  //   name: "MatriceCompetitionItemEdit",
  //   component: () =>
  //     import("../views/matrices/Competition/CompetitionItemEdit.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     matriceFriendlyName: "Competition",
  //   },
  // },
  // {
  //   path: "/organizations/:id/matrices/competition/:competitorId/edit/data",
  //   name: "MatriceCompetitionItemEditData",
  //   component: () =>
  //     import("../views/matrices/Competition/CompetitionItemEditData.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     matriceFriendlyName: "Competition",
  //   },
  // },
  {
    path: '/organizations/:id/matrices/segments/:projectId?',
    name: 'MatriceSegments',
    component: () => import('../views/matrices/Segments.vue'),
    meta: {
      requiresAuth: true,
      matriceFriendlyName: 'Segments',
      title: 'pageTitles.segments'
    }
    // children: [
    //   {
    //     path: "list",
    //     name: "MatriceSegmentsList",
    //     component: () => import("../views/matrices/Segments/SegmentsList.vue"),
    //   },
    //   {
    //     path: "segment/:segmentId",
    //     name: "MatriceSegmentsItem",
    //     component: () => import("../views/matrices/Segments/SegmentsItem.vue"),
    //   },
    //   {
    //     path: "product-line/:productLineId",
    //     name: "MatriceProductLineItem",
    //     component: () => import("../views/matrices/Segments/ProductLineItemDetails.vue"),
    //   },
    // ]
  },
  // {
  //   path: "/organizations/:id/matrices/segments/",
  //   name: "MatriceSegmentsList",
  //   // Uncomment the following line to direct the user to the competitors dashboard (after matrix completion)
  //   // component: () => import("../views/matrices/Segments.vue"),
  //   // Erase the following line to redirect the user to the competitors dashboard (after matrix completion)
  //   // Right now, clicking on "Segments" in the top left corner of the page will redirect the user to the SegmentsList page directly.
  //   // After the dashboard completion, the tabs should be uncommented in @/matrices/Segments.vue to be visible.
  //   component: () => import("../views/matrices/Segments/SegmentsList.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     matriceFriendlyName: "Segments",
  //   },
  // },
  {
    path: '/organizations/:id/matrices/segments/segment/:segmentId/:projectId?',
    name: 'MatriceSegmentsItem',
    component: () => import('../views/matrices/Segments/SegmentsItem.vue'),
    meta: {
      requiresAuth: true,
      matriceFriendlyName: 'Segments'
    }
  },
  {
    path: '/organizations/:id/matrices/segments/product-line/:productLineId',
    name: 'MatriceProductLineItem',
    component: () => import('../views/matrices/Segments/ProductLineItem.vue'),
    meta: {
      requiresAuth: true,
      matriceFriendlyName: 'Segments'
    }
  },
  {
    path: '/organizations/:id/matrices/strategies/:projectId?',
    name: 'MatriceStrategies',
    component: () => import('../views/matrices/Strategies.vue'),
    meta: {
      requiresAuth: true,
      matriceFriendlyName: 'Stratégies',
      title: 'pageTitles.strategies'
    }
    // children: [
    //   {
    //     path: "list",
    //     name: "MatriceStrategiesList",
    //     component: () => import("../views/matrices/Strategies/StrategiesList.vue"),
    //   },
    //   {
    //     path: ":strategyId",
    //     name: "MatriceStrategiesItem",
    //     component: () => import("../views/matrices/Strategies/StrategiesItem.vue"),
    //   },
    // ]
  },
  // {
  //   path: "/organizations/:id/matrices/strategies/question",
  //   name: "MatriceStrategiesList",
  //   component: () => import("../views/matrices/Strategies/SortByQuestion.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     matriceFriendlyName: "Strategies",
  //   },
  // },
  // {
  //   path: "/organizations/:id/matrices/strategies/user",
  //   name: "MatriceStrategiesList",
  //   component: () => import("../views/matrices/Strategies/SortByUser.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     matriceFriendlyName: "Strategies",
  //   },
  // },
  // {
  //   path: "/organizations/:id/matrices/strategies/:strategyId",
  //   name: "MatriceStrategiesItem",
  //   component: () => import("../views/matrices/Strategies/StrategiesItem.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     matriceFriendlyName: "Strategies",
  //   },
  // },
  {
    path: '/organizations/:id/matrices/market/:projectId?',
    name: 'MatriceMarket',
    component: () => import('../views/matrices/Market.vue'),
    meta: {
      requiresAuth: true,
      matriceFriendlyName: 'Marché',
      title: 'pageTitles.market'
    }
    // children: [
    //   {
    //     path: "list",
    //     name: "MatriceMarketList",
    //     component: () => import("../views/matrices/Market/MarketList.vue"),
    //   },
    //   {
    //     path: ":strategyId",
    //     name: "MatriceMarketItem",
    //     component: () => import("../views/matrices/Market/MarketItem.vue"),
    //   },
    // ]
  },
  {
    path: '/ansoff-matrix-template/',
    name: 'AnsoffMatrixTemplate',
    component: () => import('../views/matrices/public/AnsoffMatrixTemplate.vue'),
    meta: {
      requiresAuth: false,
      matriceFriendlyName: 'Ansoff Matrix Template',
      title: 'pageTitles.ansoffTemplate'
    }
  },
  {
    path: "/organizations/:id/matrices/team/:projectId?",
    name: "MatriceTeam",
    component: () => import("../views/matrices/Team.vue"),
    meta: {
      requiresAuth: true,
      matriceFriendlyName: "Team",
      title: 'pageTitles.teamSummary'
    },
  },
  // {
  //   path: "/organizations/:id/matrices/market/list",
  //   name: "MatriceMarketList",
  //   component: () => import("../views/matrices/Market/MarketList.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     matriceFriendlyName: "Marché",
  //   },
  // },
  // {
  //   path: "/organizations/:id/matrices/market/:projectId/:strategyId",
  //   name: "MatriceMarketItem",
  //   component: () => import("../views/matrices/Market/SortByUser.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     matriceFriendlyName: "Marché",
  //   },
  // },
  // {
  //   path: "/organizations/:id/matrices/positioning-organisation",
  //   name: "MatricePositioningOrganisation",
  //   component: () => import("../views/matrices/PositioningOrganisation.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     matriceFriendlyName: "Positionnement organisation",
  //   },
  // },
  // {
  //   path: "/organizations/:id/matrices/positioning-factor",
  //   name: "MatricePositioningFactor",
  //   component: () => import("../views/matrices/PositioningFactor.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     matriceFriendlyName: "Positionnement facteurs propres",
  //   },
  // },
  // {
  //   path: "/organizations/:id/matrices/positioning-competition",
  //   name: "MatricePositioningCompetition",
  //   component: () => import("../views/matrices/PositioningCompetition.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     matriceFriendlyName: "Positionnement compétition",
  //   },
  // },
  // -----------------  ROUTES FOR TESTING  ------------------------------------------
  // {
  //   path: "/test/sandbox",
  //   name: "Sandbox",
  //   component: () => import("../views/testingPages/Sandbox.vue"),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },
  // {
  //   path: "/test/vuelidate",
  //   name: "VuelidateTest",
  //   component: () => import("../views/testingPages/VuelidateTest.vue"),
  //   meta: {
  //     requiresAuth: true,
  //   },
  // },

]

// ------------------- ROUTER CONFIGURATIONS -------------------- //
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

let routeList = [];
const menuList = ["Admin", "OrganizationList", "UserList"];

// Applies this logic before user enters each route
router.beforeEach((to, from, next) => {

  // scroll top of the page when opening it
  window.scrollTo(0, 0);

  // add title to the page
  const title = to.meta.title;
  if (title) {
    document.title = getTranslation(title) + ' | Marketpedia';
  } else {
    document.title = "Marketpedia";
  }

  const userAuthenticated = auth.state.isAuthenticated;
  // If route does not require authentication then allow access
  if (!to.matched.some((record) => record.meta.requiresAuth)) {
    next();
    return;
  }
  // Breadcrumbs
  let index = routeList.indexOf(to.name);
  if (index !== -1) {
    routeList.splice(index + 1, routeList.length - index - 1);
  } else if (menuList.includes(to.name)) {
    routeList = [to.name];
  } else {
    routeList.push(to.name);
  }
  to.meta.routeList = routeList;
  // Allow access if user if authenticated otherwise redirect to 401 page
  if (userAuthenticated) {
    TrackerApi().trackNavigate({
      path: from.path,
      name: from.name,
      fullPath: from.fullPath,
    }, {
      path: to.path,
      name: to.name,
      fullPath: to.fullPath,
    }).then()
    auth.redirectUrl = null;
    next();
  } else {
    auth.state.redirectUrl = to.fullPath;
    next({
      name: "Login",
    });
  }
});

export default router;
