<template>
   <div class="flex items-center mb-2 gap-3">
      <label for="">
         {{ label }}
         <span v-if="required" class="text-red-500">*</span>
      </label>
      <Tooltip
         v-if="showTooltip"
         text="Hover me"
         :tooltipText="tooltipText"
         position="right"
         img="icons/tooltip-icon.svg"
      />
   </div>
   <div
      class="custom-select cursor-pointer"
      :class="{ 'cursor-not-allowed opacity-75': disabled, [customClass]: true }"
      :tabindex="tabindex"
      @blur="open = false"
   >
      <div
         class="selected"
         :class="{ 'open': open }"
         @click="() => { !disabled ? open = !open : null }"
      >
         <div v-if="selected == undefined || selected == null || showAll" class="placeholder inline-block text-gray-600 font-medium mr-auto leading-5 w-10/12 py-3">
            {{ $t('modals.questionnaireDropdownModal.placeholder') }}
         </div>
         <div v-else-if="selected" class="inline-block text-gray-600 font-medium mr-auto leading-5 w-10/12 py-3">
            {{ $t(selected?.name) }}
         </div>
         <img
            v-if="!disabled"
            src="@/assets/img/icons/icon-down-arrow.svg" alt=""
            class="inline-block absolute right-0 mr-4 arrow-image"
         />
      </div>
      <div class="items font-archivo" :class="{ selectHide: !open }">
         <div class="items-wrapper">
            <div
               v-for="(option, i) of options"
               :key="i"
               @click="selected = option; open = false; $emit('selection-changed', option);"
               class="menu-item"
            >
               <div class="title">{{ $t(option.name) }}</div>
               <div v-if="option.description" class="description mt-1">
                  {{ $t(option.description) }}
               </div>
            </div>
         </div>
         <div class="items-new-user cursor-pointer" @click.prevent="openSurveyPreview">
            <div class="flex gap-2">
               <img src="@/assets/img/icons/link-icon.svg" alt="icon">
               <span>{{$t('modals.questionnaireDropdownModal.showQuestionnaires')}}</span>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import Tooltip from '@/components/molecules/Tooltip.vue';

export default {
   name: "QuestionnaireDropdownModal",
   props: {
      label: {
         type: String,
         default: "",
         required: false,
      },
      options: {
         type: Array,
         required: true,
      },
      placeholder: {
         type: String,
         default: "Tous"
     },
      tabindex: {
         type: Number,
         required: false,
         default: 0,
      },
      value: {
         type: String,
         default: null,
      },
      showAll: {
         type: Boolean,
         default: false,
      },
      disabled: {
         type: Boolean,
         default: false,
      },
      class: {
         type: String,
         default: null,
      },
      selectedByDefault: {
         type: Object,
         default: null,
      },
      required: {
         type: Boolean,
         default: false,
      },
      showTooltip: {
         type: Boolean,
         default: false,
      },
      tooltipText: {
         type: String,
         default: "Your tooltip text",
         required: false,
      },
   },
   data() {
      return {
         open: false,
         selected: null,
      };
   },
   methods: {
      setSelected() {
         if (!this.value) {
            this.selected = this.options?.find(option => option.value === this.value);
         } else {
            this.selected = this.options?.find((option) =>
               option.value === undefined
                  ? option.name == this.value
                  : option.value == this.value
            )
         }
      },
      openSurveyPreview() {
         window.open('https://marketpedia-all.atlassian.net/l/cp/6fs92yYo', '_blank');
      }
   },
   computed: {
      customClass() {
         return this.class;
      }
   },
   watch: {
      value(newValue) {
         this.setSelected();
      },
      selectedByDefault(newValue) {
         this.selected = newValue;
         this.$emit('selection-changed', this.selected);
      },
   },
   mounted() {
      this.setSelected();
      if (this.selectedByDefault) {
      this.selected = this.selectedByDefault;
      this.$emit('selection-changed', this.selected);
    }
   },
   components: { Tooltip },
};
</script>

<style scoped>
.default {
   color: #595959;
   padding-left: 1em;
   cursor: pointer;
   user-select: none;
}

.custom-select {
   position: relative;
   width: 100%;
   text-align: left;
   outline: none;
   height: auto;
   min-height: 47px;
}

.custom-select .selected {
   height: auto;
   min-height: 35px;
   background-color: #fff;
   border-radius: 6px;
   border: 1px solid #E5E7EB;
   color: #666;
   padding-left: 0.7em;
   /* cursor: pointer; */
   user-select: none;
   min-height: 41px !important;
}

.custom-select .selected.open {
   border: 1px solid rgb(86, 208, 138);
   border-radius: 6px 6px 6px 6px;
   margin-bottom: 8px;
}

.custom-select.not-valid .selected {
   border-color: #f57f6c;
}

.custom-select .items {
   border-radius: 6px 6px 6px 6px;
   overflow: hidden;
   border-right: 1px solid lightgray;
   border-left: 1px solid lightgray;
   border-top: 1px solid lightgray;
   border-bottom: 1px solid lightgray;
   position: absolute;
   background-color: #fff;
   left: 0;
   right: 0;
   z-index: 9;
   line-height: 24px;
}

/* .custom-select .items div {
   color: #595959;
   padding-left: 1em;
   cursor: pointer;
   user-select: none;
} */

/* .custom-select .items div:hover {
   background-color: lightgray;
} */

.selectHide {
   display: none;
}

.arrow-image {
   margin-top: 15px;
}

label {
   /* margin-bottom: 5px !important; */
   display: inline-block;
   /* font-weight: 600; */
}

.title {
   font-size: 12px;
   line-height: 16px;
   font-weight: 700;
   font-family: "Archivo", sans-serif;
   padding: 8px;
   background-color: #f2f2f2;
   text-transform: uppercase;
}

.description {
   font-size: 12px;
   line-height: 16px;
   font-weight: 400;
   font-family: "Archivo", sans-serif;
   margin-top: 8px;
   margin-left: 16px;
   margin-right: 16px;
   margin-bottom: 16px;
}

.menu-item.disabled {
   pointer-events: none;
   background-color: white;
   color: #acacac;
}

.menu {
   margin-top: 8px;
   width: 239px;
   background-color: #fff;
   background-clip: padding-box;
   border: 1px solid #56d08a;
   border-radius: 0.5rem;
   color: #212529;
   cursor: pointer;
   display: flex;
   flex-direction: column;
   font-size: 1rem;
   list-style: none;
   position: absolute;
   text-align: left;
   z-index: 10;
}

.menu-item {
   color: #212529;
   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
   border-top: 1px solid transparent;
   /* Add transparent border */
   border-bottom: 1px solid transparent;
   /* Add transparent border */
   border-color: transparent;
}

.menu-item:first-child {
   border-top-left-radius: 0.5rem;
   border-top-right-radius: 0.5rem;
   border-bottom-left-radius: 0;
   border-bottom-right-radius: 0;
}

.menu-item:last-child {
   border-top-left-radius: 0;
   border-top-right-radius: 0;
   border-bottom-left-radius: 0.5rem;
   border-bottom-right-radius: 0.5rem;
}

.menu-item:hover {
   border-color: #56d08a;
   /* Apply border color on hover */
   cursor: pointer;
}

.menu-item:first-child:hover{
   border-top: 1px solid transparent;;
}

.menu-item:last-child:hover{
   border-bottom: 1px solid transparent;;
}

.menu-item:hover .title{
   background-color: rgb(213, 243, 226);
}

.menu-item.selected .title {
   color: #4f4f4f;
   font-weight: bold;
}

.menu-item.selected:first-child .title {
   border-top-left-radius: 0.5rem;
   border-top-right-radius: 0.5rem;
}

.custom-select .items-wrapper{
   max-height: 250px;
   overflow-y: scroll;
}

.custom-select .items-new-user{
   padding: 8px 15px;
   border-top: 1px solid #CBCBCB;
   display: flex;
   justify-content: flex-end;
   color: #56D08A;
}

.placeholder{
  color: #9ca3af;
}
</style>
